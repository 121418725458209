html {
  font-size: 16px;
}

@media screen and (max-width: 767px) {
  html {
    font-size: 12px;
  }
}

body {
  background: #FFE2D3;
  font-family: Arial, Helvetica, sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

@media (max-width: 768px) {
  .header {
    margin-left: 1.5rem;
  }
}


.header a {
  color: #ff0d0d;
  text-decoration: none;
  margin-right: 1.5rem;
}

.header a:hover {
  transform: scale(1.4);
}

.header a.active {
  font-weight: bolder;
}

main {
  width: 100%;
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: hidden; 
}

main#home {
  padding-left: 2rem;
  padding-right: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
}

@media (max-width: 768px) {
  main {
    width: 90%;
    max-width: none;
    height: auto; 
    font-size: 1.2em;
    border-width: 1rem;
  }
}

@media (min-width: 768px) {
  main {
    width: 100%;
    max-width: 1150px;
    font-size: 1em;
  }
}

.stephpic {
  width: 20rem;
  height: 20rem;
  border-radius: 5%;
  overflow: hidden;
  }

@media (max-width: 768px) {
  .stephpic {
    width: 80%;
    height: auto;
    max-width: 100%; 
    margin-left: 2rem;
    transform: scale(0.8);
  }
}

.textcontainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#text {
  font-size: 2rem;
  font-weight: bold;
  color: #30c75a;
  min-height: 2em;
}

@media screen and (max-width: 767px) {
  #text {
    font-size: 1rem;
    margin-top: 10px;
    margin-left: 10px;
  }
}

h3 {
  display: inline-block;
  border: 2px solid #ff0d0d;
  padding: 10px 30px;
  color: #ff0d0d;
}

@media screen and (max-width: 767px) {
  h3 {
    font-size: 0.8rem;
  }
}

.stack {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 767px) {
  .stack {
    flex-direction: row;
    align-items: flex-start;
    transform: scale(0.7);
  }
}

.stack img {
  width: 50px;
  height: 50px;
  margin: 5px;
  padding-left: 30px;
}

@media screen and (max-width: 767px) {
  .stack img {
    width: 30px;
    height: 30px;
    margin: 5px;
    padding-left: 10px;
  }
}

h1 {
  color: red;
}

@media (max-width: 768px) {
  h1 {
    transform: scale(0.7);
  }
} 

h2 {
  font-size: 2rem;
  color: black; 
  margin-top: 0.5rem; 
  margin-bottom: 1rem;
}

h4 {
  font-size: 1.5rem;
  color: black; 
  margin-top: 0.5rem; 
  margin-bottom: 2rem;
}

p {
  font-size: 1rem;
  line-height: 1.5;
  margin-bottom: 1rem;
  color: black;
}

main#about {
  padding: 2rem;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  background-color: #FFE2D3;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25),
  0 4px 4px rgba(0, 0, 0, 0.25),
  0 8px 8px rgba(0, 0, 0, 0.25),
  0 16px 16px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 767px) {
  main#about {
    margin-top: 2rem;
    margin-left: 5rem;
    width: 69.5%;
    margin-bottom: 1rem;
  }
}

.about-image-container {
  display: flex;
  justify-content: space-between;
}

.hellopic {
  width: 22rem;
  height: 22rem;
  border-radius: 5%;
  overflow: hidden;
  margin-right: 2rem;
  }

@media (max-width: 768px) {
  .hellopic {
    width: 130%;
    height: auto;
    max-width: 100%; 
    margin-left: 2rem;
  }
}

.stephparis {
  width: 18rem;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
  margin-left: 2rem;
  }

@media (max-width: 768px) {
  .stephparis {
    width: 130%;
    height: auto;
    max-width: 100%; 
    margin-left: 2rem;
  }
}

.about {
  width: 25rem;
  height: auto;
  border-radius: 5%;
  overflow: hidden;
  margin-left: 2rem;
  margin-top: 2rem;
  }

@media (max-width: 768px) {
  .about {
    width: 80%;
    height: auto;
    max-width: 100%; 
    margin-left: 1rem;
    transform: scale(0.8);
  }
}

#workcontainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 10px;
}

@media screen and (max-width: 767px) {
  #workcontainer {
    grid-template-columns: 1fr;
    margin-left: 4rem;
    transform: scale(0.9);
    margin-top: -10rem;
    margin-bottom: -5rem;
  }
}

.project {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25),
  0 4px 4px rgba(0, 0, 0, 0.25),
  0 8px 8px rgba(0, 0, 0, 0.25),
  0 16px 16px rgba(0, 0, 0, 0.25);
  background-color: white;
}

.project img {
  width: 180px;
  height: 180px;
  object-fit: cover;
  margin-bottom: 20px;
}

.project:hover {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.project:nth-child(5) img {
  object-fit: cover;
  object-position: left;
  margin-bottom: 20px;
}

.button-container {
  display: inline-block;
  margin: 0.5rem;
}

button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #DCAE96;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25),
  0 4px 4px rgba(0, 0, 0, 0.25),
  0 8px 8px rgba(0, 0, 0, 0.25),
  0 16px 16px rgba(0, 0, 0, 0.25);
}


@media screen and (max-width: 767px) {
  button {
    margin-top: 1rem;
  }
}

button:hover {
  background-color: #3e8e41;
}

main#contact {
  padding: 2rem;
  width: 100%;
  max-width: 1200px; 
  margin-left: auto;
  margin-right: auto;
}

#contact {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.form-container {
  display: flex;
  justify-content: center;
  margin-top: 0.1rem;
}

@media (max-width: 768px) {
  .contact-form {
    transform: scale(0.4);
    margin-top: -12rem;
    margin-bottom: -7rem;
  }
}

a {
  color: #30c75a;
  text-decoration: none;
  padding-right: 1rem;
}

a:hover {
  text-decoration: underline;
}


.contactcontainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 62.5ch;
  text-align: center;
}

.contactcontainer p {
  padding-bottom: 30px;
}

.contactcontainer, .four {
  margin-inline: auto;
}

.boxes {
  min-width: 520px;
}

#contact form input[type="text"],
#contact form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 1.1rem;
}

#contact form textarea {
  height: 150px;
  margin-bottom: 30px;
}

#contact form input[type="submit"] {
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #DCAE96;
  color: white;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.25),
  0 4px 4px rgba(0, 0, 0, 0.25),
  0 8px 8px rgba(0, 0, 0, 0.25),
  0 16px 16px rgba(0, 0, 0, 0.25);
}

@media screen and (max-width: 767px) {
  #contact form input {
    margin-top: 1rem;
  }
}

#contact form input[type="submit"]:hover {
  background-color: #3e8e41;
}

.row {
  margin-inline: auto;
}

@media (max-width: 768px) {
  #contact form {
    grid-template-columns: repeat(1, 1fr);
  }

  #contact form input[type="text"],
  #contact form textarea {
    margin-bottom: 20px;
  }
}


.side {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 70px;
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;
  padding: 20px 0;
}

@media screen and (max-width: 767px) {
  .side {
    margin-top: 25px;
    left: -15px;
  }
}

.side a {
  display: block;
  padding: 10px;
  color: #30c75a;
  text-decoration: none;
  text-align: center;
  transition: background-color 0.2s ease-in-out;
}

.side a:hover {
  transform: scale(1.4);
}

@media screen and (max-width: 767px) {
  .side a {
    transform: scale(0.7);
    padding-top: 2rem;
    margin-top: 2rem;
  }
}

.side::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  height: 31rem;
  background-color: #30c75a;
}

@media screen and (max-width: 767px) {
  .side::after {
    width: 2px;
    height: 17rem;
  }
}

footer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
}